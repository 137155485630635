import { ReactNode, useContext } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import { AuthContext } from '../contexts/Auth0Context';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

const useCurrentRole = () => {
  // Logic here to get current user role
  const context = useContext(AuthContext);
  if (!context) throw new Error('Auth context must be ready for Guard');

  const role = context.user?.role;
  if (!role) throw new Error('Role must be set to user for validation by Guard');

  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const currentRole = useCurrentRole();

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
