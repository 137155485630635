// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1000 1000"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
      >
        <circle cx={500} cy={500} r={495} fill="none" />
        <clipPath id="a">
          <circle cx={500} cy={500} r={495} />
        </clipPath>
        <g clipPath="url(#a)">
          <circle cx={500} cy={500} r={495} fill="#ffa400" />
          <g fill="#fff" fillRule="nonzero">
            <use xlinkHref="#b" />
            <use xlinkHref="#b" />
          </g>
          <path
            d="M1305.35 644.799c7.94-8.468 15.881-12.702 23.821-12.702 8.663 0 15.701 5.716 21.115 17.148s8.121 25.191 8.121 41.28c0 35.565-10.828 63.932-32.484 85.102-37.537 36.412-80.128 54.617-127.771 54.617-68.578 0-118.748-33.659-150.51-100.978s-47.644-155.173-47.644-263.561c0-103.308 11.369-221.011 34.108-353.109s56.306-245.567 100.701-340.406 97.273-142.26 158.632-142.26c34.649 0 61.9 18.841 81.752 56.523s29.777 91.664 29.777 161.947c0 100.767-23.642 217.623-70.924 350.568s-111.71 264.196-193.281 393.754c5.053 34.718 14.076 59.698 27.07 74.94s30.68 22.863 53.058 22.863c12.271 0 26.167-4.234 41.688-12.701s29.777-19.476 42.771-33.025zm-35.733-889.122c-16.603 0-35.372 35.142-56.306 105.425s-39.342 157.501-55.223 261.656-24.544 204.074-25.988 299.761c51.253-99.074 92.039-198.359 122.358-297.856s45.478-190.314 45.478-272.452c0-64.356-10.107-96.534-30.319-96.534z"
            fill="#f29f0d"
            fillRule="nonzero"
          />
        </g>
        <defs>
          <path
            id="b"
            d="M1011.01 437.761c2.352-.847 6.272-1.27 11.76-1.27 11.76 0 20.776 4.233 27.048 12.701s9.408 19.9 9.408 34.295c0 26.25-4.704 46.785-14.112 61.603s-23.52 25.616-42.336 32.39c-36.064 12.702-74.48 19.052-115.248 19.052-34.495 0-67.031-5.08-97.607-15.242-22.736 39.799-47.824 80.868-75.264 123.207-31.36 48.267-58.212 81.291-80.556 99.074s-47.628 26.673-75.852 26.673c-31.359 0-55.075-13.125-71.147-39.375s-26.068-69.436-29.988-129.558l-5.88-92.722c-24.304 71.129-46.06 125.323-65.268 162.582s-38.416 63.085-57.624 77.48-41.748 21.593-67.62 21.593c-32.144 0-57.427-13.548-75.851-40.645s-29.988-69.86-34.692-128.288c-9.408-118.549-14.112-222.28-14.112-311.192v-44.456c.784-27.944 7.84-47.42 21.168-58.428s33.32-16.513 59.976-16.513c20.383 0 35.475 4.869 45.275 14.607s14.7 26.039 14.7 48.902c0 97.38 5.488 223.974 16.464 379.782 53.312-121.09 102.312-247.26 147-378.512 8.624-25.403 19.012-42.551 31.164-51.442s26.852-13.337 44.1-13.337c21.168 0 35.867 4.658 44.099 13.972s12.348 25.827 12.348 49.537c0 97.38 5.488 223.974 16.464 379.782 47.04-75.364 82.32-135.485 105.84-180.365-11.76-24.556-17.64-53.77-17.64-87.642 0-28.79 5.88-56.734 17.64-83.831s27.832-49.113 48.216-66.049 43.512-25.404 69.384-25.404c22.736 0 41.16 8.68 55.271 26.039s21.168 42.551 21.168 75.575c0 38.106-9.408 81.715-28.224 130.828 29.792-1.693 69.384-8.044 118.776-19.052l31.752-6.351z"
          />
        </defs>
      </svg>
    </Box>
  );
}
