import { JwksClient } from 'jwks-rsa';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
// @types
import { ActionMap, AuthState, AuthUser, Auth0ContextType } from '../@types/authentication';
//
import { auth0Config } from '../config';
import axios from '../utils/axios';
var atob = require('atob');
// ----------------------------------------------------------------------

let auth0Client: Auth0Client | null = null;

let token: string;

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

enum Types {
  init = 'INITIALIZE',
  login = 'LOGIN',
  logout = 'LOGOUT'
}

type Auth0AuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.login]: {
    user: AuthUser;
  };
  [Types.logout]: undefined;
};

type Auth0Actions = ActionMap<Auth0AuthPayload>[keyof ActionMap<Auth0AuthPayload>];

const reducer = (state: AuthState, action: Auth0Actions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }
  if (action.type === Types.login) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    };
  }
  return state;
};

const AuthContext = createContext<Auth0ContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          client_id: auth0Config.clientId || '',
          domain: auth0Config.domain || '',
          audience: auth0Config.audience || '',
          redirect_uri: window.location.origin
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();

          // Set token to access the API afterwards with helper axios
          token = await auth0Client.getTokenSilently();
          axios.defaults.headers.common.Authorization = 'Bearer ' + token;

          dispatch({
            type: Types.init,
            payload: { isAuthenticated, user: user || null }
          });
        } else {
          console.log('RESET!');
          dispatch({
            type: Types.init,
            payload: { isAuthenticated, user: null }
          });
        }
      } catch (err) {
        dispatch({
          type: Types.init,
          payload: { isAuthenticated: false, user: null }
        });
      }
    };

    initialize();
  }, []);

  const login = async () => {
    console.log('Showing Auth Popup');
    await auth0Client?.loginWithPopup();
    console.log('Checking authentication ...');
    const isAuthenticated = await auth0Client?.isAuthenticated();
    console.log('Result:', isAuthenticated);

    if (isAuthenticated) {
      const user = await auth0Client?.getUser();
      dispatch({ type: Types.login, payload: { user: user || null } });
    }
  };

  const logout = () => {
    auth0Client?.logout();
    dispatch({ type: Types.logout });
  };

  const resetPassword = (email: string) => {};

  const updateProfile = () => {};

  let roles = ['user'];
  if (token) {
    let parsedToken = parseJwt(token);
    roles = parsedToken['https://wunderbon.io/roles'];
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        user: {
          id: state?.user?.sub,
          photoURL: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.given_name || state?.user?.email,
          role: roles[0]
        },
        login,
        logout,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function parseJwt(token: string) {
  if (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c: string) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } else {
    return {};
  }
}

export { AuthContext, AuthProvider };
