import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainNavbar from './MainNavbar';

// ----------------------------------------------------------------------

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="caption"
              component="p"
              sx={{ fontSize: pxToRem(16), marginBottom: pxToRem(16), color: 'text.secondary' }}
            >
              © 2020 - 2022 wunderbon® - All rights reserved
            </Typography>
            <Typography
              variant="caption"
              component="p"
              sx={{ fontSize: pxToRem(16), marginBottom: pxToRem(16), color: 'text.secondary' }}
            >
              Handcrafted for a better future 🌳
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
}
