// ----------------------------------------------------------------------

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({
  sm,
  md,
  lg,
  xl
}: {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}) {
  return {
    '@media (min-width:576px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:768px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:992px)': {
      fontSize: pxToRem(lg)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(xl)
    }
  };
}

function responsiveLineHeights({
  sm,
  md,
  lg,
  xl
}: {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}) {
  return {
    '@media (min-width:576px)': {
      lineHeight: pxToRem(sm)
    },
    '@media (min-width:768px)': {
      lineHeight: pxToRem(md)
    },
    '@media (min-width:992px)': {
      lineHeight: pxToRem(lg)
    },
    '@media (min-width:1200px)': {
      lineHeight: pxToRem(xl)
    }
  };
}

const FONT_PRIMARY = 'greycliff-cf, sans-serif'; // Local Font
const FONT_SECONDARY = 'Quicksand, sans-serif'; // Google Font

const typography = {
  fontFamily: FONT_SECONDARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  lineHeight: 35.2,
  fontSize: 16,
  h1: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    // lineHeight: 80 / 64,
    // lineHeight: '67.2px',
    fontSize: pxToRem(36),
    ...responsiveLineHeights({ sm: 54, md: 58, lg: 62, xl: 68 }),
    ...responsiveFontSizes({ sm: 42, md: 46, lg: 50, xl: 56 })
  },
  h2: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    // lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveLineHeights({ sm: 48, md: 52, lg: 58, xl: 62 }),
    ...responsiveFontSizes({ sm: 38, md: 42, lg: 48, xl: 52 })
  },
  h3: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32, xl: 34 })
  },
  h4: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24, xl: 24 })
  },
  h5: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20, xl: 20 })
  },
  h6: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18, xl: 18 })
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  body1: {
    fontFamily: FONT_SECONDARY,
    //fontSize: pxToRem(16),
    ...responsiveLineHeights({ sm: 32, md: 36, lg: 38, xl: 40 }),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18, xl: 20 })
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize'
  }
} as const;

export default typography;
